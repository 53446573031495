.main-frnachise {
  min-height: 100vh;
  background-color: rgb(223, 33, 33);
}
.full-width-card {
  width: 100%;
  background-color: white; /* Adjust the background color as needed */
  color: #fff; /* Adjust the text color as needed */
  padding: 20px;
  box-sizing: border-box;
}

.centered-content-card {
  width: 100%; /* Adjust the width as needed */
  margin: 0 auto; /* Center the card horizontally */
  height: 50px; /* Adjust the height as needed */
  border: 1px solid #ccc;
  display: flex;
  align-items: center;
  justify-content: center;
}

.centered-content-card p {
  color: black;
  letter-spacing: 0em;
  direction: ltr;
  font-size: 33.5567px;
  font-weight: normal;
  font-style: normal;
  text-decoration: none;
  text-transform: capitalize;
  margin-top: 30px;
}

#comp-lpgdgb2h2 {
  display: grid;
  grid-template-rows: minmax(max-content, 100.00003612879918%);
  grid-template-columns: minmax(0px, 1fr);
  position: relative;
  box-sizing: border-box;
  padding-top: 40px;
  padding-bottom: 40px;
  flex-grow: 1;
}

.card {
  background-color: white; /* White background */
  height: 260px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 10px;
  margin-left: 5%;
  margin-right: 5%;
  border-top-left-radius: 40px; /* Border radius for rounded corners */
  border-bottom-right-radius: 40px;
  padding-left: 15%;
  padding-right: 15%;
  padding-bottom: 10px;
}

.left-content {
  text-align: left;
  font-size: 24px;
}

.right-content {
  text-align: right;
  padding: 50px;
}

.right-content img {
  width: 200px; /* Set the desired width */
  height: 200px; /* Set the desired height */
  object-fit: cover; /* Maintain aspect ratio and cover the container */
  border-radius: 10px; /* Optional: Add border-radius for rounded corners */
}

.button-section {
  display: flex;
  width: 100%;
}

.button {
  flex-grow: 1;
  height: 130px;
  background-color: white;
  color: white;
  border: none;
  outline: none;
  cursor: pointer;
  transition: background-color 0.3s;
  color: black;
  font-size: 20px;
  width: 25%;
  transition: background-color 0.3s;
  border-right: 1px solid rgb(223, 33, 33);
}

.button:hover {
  background-color: white; /* Change to the desired color on hover */
}

.button:active {
  background-color: rgb(
    223,
    33,
    33
  ); /* Change to the desired color when active (clicked) */
}

.button.active {
  background-color: rgb(223, 33, 33);
  color: rgb(242, 234, 231); /* Set the initial active button color */
}

.selected-content {
  padding: 60px;
  color: rgb(242, 234, 231);
}

@media (max-width: 600px) {
  .centered-content-card p {
    font-size: 15.5567px;
    margin-top: 13px;
    text-align: center;
  }

  .left-content {
    font-size: 12px;
  }

  .right-content img {
    width: 100px; /* Set the desired width */
    height: 100px; /* Set the desired height */
  }

  .right-content {
    padding: 10px;
  }

  .card {
    height: 130px;
    padding: 0px 20px;
  }

  .button {
    height: 70px;
    font-size: 12px;
    text-overflow: ellipsis;
  }

  #comp-lpgdgb2h2 {
    padding-bottom: 10px;
  }

  .button-section {
    margin-top: 40px;
  }
}
