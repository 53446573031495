.container {
  font-family: "Arial", sans-serif;
  text-align: center;
  padding: 20px;
}

header h1 {
  font-size: 24px;
  color: #d10000;
  margin-bottom: 20px;
}

.presence-section {
  max-width: 40%;
  text-align: left;
  margin-top: 5%;
  margin-left: 10%;
}

.presence-section p{
  font-size: 20px;
}
/* Mobile View Adjustments */
@media (max-width: 768px) {
  .presence-section {
    max-width: 90%;
    margin: 10% auto;
    text-align: center;
  }

  .presence-section h2 {
    font-size: 20px;
  }

  .presence-section p {
    font-size: 14px;
  }

  .map-section {
    flex-direction: column;
    align-items: center;
  }

  .map-image {
    width: 80%;
    max-width: 600px;
    margin: 20px auto;
  }

  .legend {
    text-align: center;
  }

  .legend p,
  .legend ul {
    font-size: 14px;
  }
}

/* Tablet View Adjustments */
@media (max-width: 1024px) {
  .presence-section {
    max-width: 70%;
    margin-left: 15%;
  }

  .map-image {
    width: 70%;
    margin: 20px auto;
  }
}

.legend p {
  font-size: 16px;
}

.legend ul {
  list-style-type: none;
  padding: 0;
}

.legend li {
  margin-bottom: 5px;
}

.head-office {
  background-color: blue;
  width: 15px;
  height: 15px;
  display: inline-block;
  margin-right: 5px;
}

.branch-office {
  background-color: green;
  width: 15px;
  height: 15px;
  display: inline-block;
  margin-right: 5px;
}

.partner-office {
  background-color: orange;
  width: 15px;
  height: 15px;
  display: inline-block;
  margin-right: 5px;
}
