.sideBarMainContainer {
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;

  right: 0%;
  right: -80px;
  padding-top: 20px;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  background: #d32402;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  cursor: pointer;
  transition-duration: 1s;
}

.whatsappsideBarMainContainer {
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;

  right: 0%;
  right: -80px;
  padding-top: 20px;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  background: #d32402;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  cursor: pointer;
  transition-duration: 1s;
}

.sideBarAfterScroll {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 80%;
  right: 0%;
  padding: 2px 8px;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  background: #d32402;
  border-top-left-radius: 274px;
  border-bottom-left-radius: 274px;
  z-index: 1;

  /* transition: all 1s ease 0s; */
  /* transition-property: all; */
  transition-duration: 1s;
  /* transition-timing-function: ease; */
  /* transition-delay: 0s */
}

.whatsappsideBarAfterScroll {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 92%;
  right: 0%;
  padding: 2px 8px;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  background: #25d366;
  border-top-left-radius: 274px;
  border-bottom-left-radius: 274px;
  z-index: 1;
  transition-duration: 1s;
}

.brochuresideBarAfterScroll {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 70%;
  right: 0%;
  padding: 2px 8px;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  background: #d32402;
  border-top-left-radius: 274px;
  border-bottom-left-radius: 274px;
  z-index: 1;
  transition-duration: 1s;
}

.stickButttons {
  margin: 5px 0px;
  text-align: center;
  color: white;
  width: 100%;
}

@media only screen and (max-width: 412px) {
  .sideBarMainContainer {
    display: none;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: fixed;

    right: 0%;
    right: -80px;
    padding-top: 20px;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    background: #d32402;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    cursor: pointer;
    transition-duration: 1s;
  }

  .whatsappsideBarMainContainer {
    display: none;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: fixed;

    right: 0%;
    right: -80px;
    padding-top: 20px;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    background: #d32402;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    cursor: pointer;
    transition-duration: 1s;
  }

  .whatsappsideBarAfterScroll {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 92%;
    right: 0%;
    padding: 2px 8px;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    background: #25d366;
    border-top-left-radius: 274px;
    border-bottom-left-radius: 274px;
    z-index: 1;
    transition-duration: 1s;
  }

  .brochuresideBarAfterScroll {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 78%;
    right: 0%;
    padding: 2px 8px;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    background: #d32402;
    border-top-left-radius: 274px;
    border-bottom-left-radius: 274px;
    z-index: 1;
    transition-duration: 1s;
  }

  .sideBarAfterScroll {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 85%;
    right: 0%;
    padding: 2px 8px;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    background: #d32402;
    border-top-left-radius: 274px;
    border-bottom-left-radius: 274px;
    z-index: 1;

    /* transition: all 1s ease 0s; */
    /* transition-property: all; */
    transition-duration: 1s;
    /* transition-timing-function: ease; */
    /* transition-delay: 0s */
  }

  .stickButttons {
    margin: 5px 0px;
    text-align: center;
    color: white;
    width: 100%;
  }

  .contactUsSideBar {
    /* transform: rotate(-90deg); */
    color: #ffff;
    font-weight: 600;
    margin: 10px 0px;
    text-align: center;
    cursor: pointer;
    letter-spacing: 1px;
    font-size: 12px;
  }
  .whatsappSideButton {
    font-weight: 600;
    letter-spacing: 1px;
    cursor: pointer;
    font-size: 12px;
  }
  .getEstimateSideButton {
    font-weight: 600;
    letter-spacing: 1px;
    cursor: pointer;
    font-size: 12px;
  }
  .ModalCloseIconContainer {
    text-align: right;
  }
  .ModalCloseIcon {
    font-size: 20px;
    color: gray;
  }
  .ModalCloseIcon:hover {
    font-size: 20px;
    color: black;
    cursor: pointer;
  }
  .modalFormContainer {
    text-align: center;
    margin: auto;
    width: 280px;
    /* box-shadow: 0px 0px 2px dodgerblue; */
    padding: 20px 40px;
  }
  .inputSection {
    display: flex;
    justify-content: space-between;
    margin: 20px 0px;
  }
  .enquirySubmitButton {
    margin-top: 20px;
    background: #eb0a0a;
    color: #ffff;
    padding: 8px 14px;
    border: none;
    border-radius: 3px;
    letter-spacing: 1px;
  }
  .enquirySubmitButton:hover {
    margin-top: 20px;
    background: #eb0a0a;
    color: #ffff;
    padding: 8px 14px;
    border: none;
    border-radius: 3px;
    letter-spacing: 1px;
    cursor: pointer;
    box-shadow: 0px 0px 2px #eb0a0a;
  }

  .inputSection label {
    font-weight: 600;
  }
  .inputSection input {
    /* padding: 5px 10px; */
  }
}
