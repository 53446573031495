.partner-cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 20px;
  margin-top: 10px;
}

.partner-card {
  background-color: #e8f4fc;
  border: 1px solid #c0dfe7;
  border-radius: 8px;
  width: 400px;
  /* padding: 20px; */
  margin: 10px;
}

header h1 {
  font-size: 24px;
  color: #d10000;
  margin-bottom: 20px;
  text-align: center;
  margin-top: 20px;
}

.partner-card__logo {
  width: 100%; /* Use a percentage-based width */
  height: auto; /* Maintain aspect ratio */
  object-fit: contain; /* Ensures the image maintains its aspect ratio */
  margin: 0 auto; /* Center the image */
  display: block; /* Center the image by making it a block element */
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
.partner-card__header {
  background-color: #d0e7f1;
  padding: 0px;
  border-radius: 8px 8px 0 0;
  text-align: center;
}

.partner-card__location {
  display: block;
  font-weight: bold;
  color: #0073e6;
  margin-bottom: -1px;
  margin-top: 10px;
}


.partner-card__company {
  font-size: 18px;
  margin: 0;
  color: #333;
}

.partner-card__body {
  padding: 15px;
}

.partner-card__contact {
  margin-bottom: 15px;
}

.partner-card__contact-label {
  font-weight: bold;
  color: #333;
}

.partner-card__contact-person {
  font-size: 16px;
  color: #555;
}

.partner-card__footer {
  text-align: center;
}

.partner-card__button {
  background-color: #0073e6;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.partner-card__button:hover {
  background-color: #005bb5;
}

/* Media Queries for Mobile View */
@media (max-width: 768px) {
  .partner-cards {
    flex-direction: column;
    align-items: center;
  }

  .partner-card {
    width: 90%;
    padding: 15px;
  }

  .partner-card__company {
    font-size: 16px;
  }

  .partner-card__contact-person {
    font-size: 14px;
  }

  .partner-card__button {
    padding: 8px 16px;
    font-size: 14px;
  }
  .partner-card__logo {
    width: 100%; /* Adjust the width for tablets */
  }
}

@media (max-width: 480px) {
  .partner-card {
    width: 100%;
    padding: 10px;
  }

  .partner-card__company {
    font-size: 14px;
  }

  .partner-card__contact-person {
    font-size: 12px;
  }

  .partner-card__button {
    padding: 6px 12px;
    font-size: 12px;
  }

  .partner-card__logo {
    width: 100%; /* Adjust the width for mobile screens */
  }
}
