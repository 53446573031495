.btn:hover {
  color: white;
  background-color: #d32402;
  border: none;
}

.btn:focus {
  color: white;
  background-color: #d32402;
  border: none;
}

.btn1 {
  background-color: #d32402;
  color: white;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  transition-duration: 0.4s;
  cursor: pointer;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  padding: 5px 10px 5px 9px;
}

.btn1:hover {
  color: #d32402;
  background-color: #fff;

  border-width: 0.3vh;
  border-color: #d32402;
}
.btn1:focus {
  color: #d32402;
  background-color: #fff;

  border-width: 0.3vh;
  border-color: #d32402;
}

.btn {
  background-color: white; /* Green */
  text-align: center;
  text-decoration: none;
  display: inline-block;
  transition-duration: 0.4s;
  cursor: pointer;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  color: #d32402;
}
